.catalogue {
  &-item {
    border-bottom: 1px solid $grey-2;
    color: $default-color;
    display: block;
    padding: 2rem 0;

    &:hover .catalogue-line,
    &:focus .catalogue-line {
      width: 5rem;
    }

    &:last-child {
      border: 0;
    }
  }

  &-time {
    color: $default-tint;
    font-family: $serif-secondary;
    letter-spacing: .5px;
  }

  &-title {
    color: $default-shade;
    display: block;
    font-family: $sans-serif;
    font-size: 2rem;
    font-weight: 700;
    margin: .5rem 0;
  }

  &-line {
    @include transition(all .3s ease-out);
    border-top: .2rem solid $orange;
    display: block;
    width: 2rem;
  }
}
