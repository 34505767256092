// Colors
$default-color: #555;
$default-shade: #353535;
$default-tint: #aaa;
$grey-1: #979797;
$grey-2: #e5e5e5;
$grey-3: #f9f9f9;
$white: #fff;
$blue: #4a9ae1;
$orange: #e98521;
$shadow-color: rgba(0, 0, 0, .2);
$code-color: #bf616a;

// Fonts


//font-family: 'Open Sans', sans-serif;

$serif-primary: 'Merriweather', serif;
$serif-secondary: 'Merriweather', serif;
$sans-serif: 'Roboto', sans-serif;
$monospaced: Menlo, Monaco, monospace;

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
          transition: $args;
}
