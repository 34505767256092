
.callout {
  font-family: $serif-primary; 
  background-color: $grey-3;
  padding: 1rem;
  border-radius: 1rem;
  margin: 2rem;
  
  border-style: solid;
  border-width: 1px;
  border-color: $grey-2;
  font-size: 1.2rem;
  
  &-info {
    font-family: $serif-secondary;
    letter-spacing: 0.5px;
    font-size: 1rem;

    span {
      font-style: italic;
    }
  }
}


.post {
  padding: 3rem 0;

  &-info {
    color: $default-tint;
    font-family: $serif-secondary;
    letter-spacing: 0.5px;

    span {
      font-style: italic;
    }
  }

  &-title {
    color: $default-shade;
    font-family: $sans-serif;
    font-size: 4rem;
    margin: 1rem 0;
	line-height: 1.25;
  }

  &-line {
    border-top: 0.4rem solid $default-shade;
    display: block;
    margin: 0 0 3rem;
    width: 4rem;
  }

  p {
    margin: 0 0 1rem;
	max-width: 40rem;
    text-align: justify;
	line-height: 1.5;
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    margin: 0 auto 0.5rem;
  }

  img + em {
    color: $default-tint;
    display: block;
    font-family: $sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    text-align: center;
  }

  // CSS for making emoji inline
  img.emoji {
    display: inline-block;
    left: 0;
    transform: none;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
    padding: 0;
    margin: 0;
  }
}
